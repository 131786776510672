import ApiService from '../Api';

class BarcodeServices extends ApiService {
  constructor() {
    super('/archons');
  }

  store(archon, body) {
    return this.api.post(`${this.resource}/${archon}/barcodes`, body);
  }
  
  destroy(archon, barcode) {
    return this.api.delete(`${this.resource}/${archon}/barcodes/${barcode}`);
  }
  
  storeBulk(archon, body) {
    return this.api.post(`${this.resource}/${archon}/barcodes/store-bulk`, body);
  }
}

export default new BarcodeServices();