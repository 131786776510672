<template>
  <v-card flat>
    <v-card-text>
      <div class="d-flex flex-row">
        <b>Sku:</b> &nbsp;
        <div>{{ sku.sku }}</div>
      </div>
      <div class="d-flex flex-row">
        <b>Product:</b> &nbsp;
        <div>{{ sku.product.name }}</div>
      </div>
      <div class="d-flex flex-row">
        <b>Creado:</b> &nbsp;
        <div>{{ sku.created_at }}</div>
      </div>
      <div class="d-flex flex-row">
        <b>Actualizado:</b> &nbsp;
        <div>{{ sku.updated_at }}</div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    sku: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>