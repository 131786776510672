import ApiService from '../Api';

class PriceServices extends ApiService {
  constructor() {
    super('/archons');
  }

  index(archon, params = {}) {
    return this.api.get(`${this.resource}/${archon}/prices`, { params });
  }

  store(archon, body) {
    return this.api.post(`${this.resource}/${archon}/prices`, body);
  }

  destroy(archon, price) {
    return this.api.delete(`${this.resource}/${archon}/prices/${price}`);
  }
}

export default new PriceServices();