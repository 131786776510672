<template>
  <v-card flat>
    <v-data-table
      :headers="headers"
      :items="priceables"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      fixed-header
    >
      <template v-slot:item.price="{ item }">
        <div>
          <div> {{ item.price.name }} </div>
          <v-chip
            outlined
            x-small
            :color="translatorsType[item.price.type].color"
          >
            {{ translatorsType[item.price.type].value }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.package="{ item }">
        <div>{{ item.package.name }}</div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { TRANSLATORS_TYPE } from '@/constants';
export default {
  props: {
    priceables: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      translatorsType: TRANSLATORS_TYPE,
    };
  },
  methods: {},
  computed: {
    headers() {
      return [
        { text: 'Price', value: 'price', sortable: false },
        { text: 'Paquete', value: 'package', sortable: false },
        { text: 'Valor', value: 'value', sortable: false },
        { text: 'Unidades', value: 'units', sortable: false },
      ];
    },
  },
};
</script>