<template>
  <v-card outlined>
    <v-card-title>
      {{ barcode.value }}
    </v-card-title>
    <v-card-actions>
      <small>
        <b> Agregado: {{ getDateHuman(barcode.created_at) }} </b>
      </small>
      <v-spacer />
      <v-btn
        barcodeId
        color="red"
        small
        @click="removeBarcode"
        dark
      >
        Eliminar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getDateFormat } from '@/constants/helpers';
import JsBarcode from 'jsbarcode';
export default {
  props: {
    barcode: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: -1,
    },
    height: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {};
  },
  mounted() {
    // this.generateBarcode();
  },
  methods: {
    getDateHuman(date) {
      if (date) return getDateFormat(date);
      else return '-';
    },
    generateBarcode() {
      console.log(this.barcode);
      const value = this.barcode?.value ?? 'test';
      if (value !== '') {
        JsBarcode(`#${this.barcode.uuid}`, value, {
          height: 30,
        });
      }
    },
    removeBarcode() {
      this.$emit('onRemove', {
        barcode: this.barcode,
        index: this.index,
      });
    },
  },
};
</script>
