r<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="80%"
  >
    <v-card>
      <v-card-title>
        <v-icon left>mdi-currency-usd</v-icon>
        Precios para {{ sku.sku }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-form
              ref="form"
              @submit="submitNone"
              class="d-flex justify-space-between"
            >
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-select
                    label="Paquete"
                    v-model="priceable.package_id"
                    :items="packages"
                    item-text="name"
                    item-value="id"
                    :rules="[isRequired]"
                    @change="updateUnits"
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    label="Unid. por Paquete"
                    v-model="priceable.units"
                    type="number"
                    step="1"
                    :rules="[isRequired, isInteger]"
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-select
                    label="Tipo precio"
                    v-model="priceable.price_id"
                    :items="prices"
                    item-text="name"
                    item-value="id"
                    :rules="[isRequired]"
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    label="Precio Unit."
                    v-model="priceable.value"
                    type="number"
                    step="0.1"
                    :rules="[isRequired]"
                  />
                </v-col>
              </v-row>
            </v-form>
            <div class="d-flex justify-end mt-4">
              <v-btn
                @click="addPriceable"
                small
                color="primary"
                :loading="loadingStore"
                :disabled="loadingStore"
              >
                <v-icon left>mdi-plus</v-icon>
                Agregar precio
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="8">
            <v-data-table
              :headers="headers"
              :items="priceables"
              class="elevation-0"
              disable-pagination
              hide-default-footer
              fixed-header
              item-key="uuid"
              :height="250"
            >
              <template v-slot:item.price_name="{ item }">
                <v-chip
                  outlined
                  small
                  :color="item.price_type === 'SELL' ? 'success' : 'warning'"
                >
                  {{ item.price_name }}
                </v-chip>
              </template>
              <template v-slot:item.units="{ item }">
                <v-text-field
                  v-model="item.units"
                  dense
                  type="number"
                  class="text__text-center"
                  step="1"
                />
              </template>
              <template v-slot:item.value="{ item }">
                <v-text-field
                  v-model="item.value"
                  dense
                  type="number"
                  class="text__text-center"
                  step="0.1"
                />
              </template>
              <template v-slot:item.price_unit="{ item }">
                {{ getPriceUnit(item) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  small
                  color="red"
                  @click="removePrice(item)"
                  :loading="item.id === loadingRemove"
                  :disabled="item.id === loadingRemove"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn
                  v-if="item.id !== -1"
                  icon
                  small
                  color="success"
                  @click="updatePrice(item)"
                  :loading="item.id === loadingUpdate"
                  :disabled="item.id === loadingUpdate"
                >
                  <v-icon>mdi-content-save-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="grey lighten-1"
          @click="closeDialog"
        >
          Cerrar
        </v-btn>
        <!-- <v-btn
          small
          color="grey lighten-1"
          @click="closeDialog"
        >
          Guardar cambios
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isInteger } from '@/constants/rules';
import PriceableServices from '@/store/services/general/PriceableServices';
import { mapGetters } from 'vuex';
import { uuid } from '@/constants/helpers';
export default {
  props: {
    prices: {
      type: Array,
      default: () => [],
    },
    packages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,

      priceable: {
        id: -1,
        price_id: '',
        value: '',
        package_id: '',
        units: '',
      },
      priceables: [],
      sku: {
        id: -1,
        sku: '',
      },

      // rules
      isRequired: value => !!value || 'Campo requerido',
      isInteger: value => isInteger(value) || 'Este campo debe ser entero',

      loadingStore: false,
      loadingRemove: 0,
      loadingUpdate: 0,
    };
  },
  methods: {
    updateUnits(event) {
      const index = this.packages.findIndex(packa => packa.id === event);
      this.priceable.units = this.packages[index].value_default;
    },
    async addPriceable() {
      if (this.$refs.form.validate()) {
        let priceable = Object.assign(this.priceable);
        const priceIndex = this.prices.findIndex(price => price.id === priceable.price_id);
        priceable.uuid = uuid();
        priceable.price_name = this.prices[priceIndex].name;
        priceable.price_type = this.prices[priceIndex].type;
        if (this.existPriceableBuy(priceable.price_type, priceable.price_id)) {
          this.$notify({
            group: 'petition',
            title: 'Precios',
            type: 'warning',
            text: 'No puede seleccionar mas de un precio tipo COMPRA',
          });
        } else {
          const packageIndex = this.packages.findIndex(packag => packag.id === priceable.package_id);
          priceable.package_name = this.packages[packageIndex].name;
          if (this.sku.id !== -1) await this.storePriceable(priceable);  
          else this.priceables.unshift(priceable);
          this.resetData();
        }
      }
    },
    getPriceUnit(item) {
      return (item.value / item.units).toFixed(2);
    },
    existPriceableBuy(priceType, priceId) {
      if (priceType === 'SELL') return false;
      else {
        const index = this.priceables.findIndex(priceable => priceable.price_id === priceId);
        return index !== -1;
      }
    },
    async updatePrice(item) {
      try {
        const body = Object.assign({}, item);
        body.priceable_type = 'sku';
        body.priceable_id = this.sku.id;
        this.loadingUpdate = item.id;
        await PriceableServices.update(this.user.archon.id, item.id, body);
        this.loadingUpdate = 0;
        this.$notify({
          group: 'petition',
          title: 'Precios',
          type: 'success',
          text: 'Precio actualizado',
        });
        this.$emit('onUpdatePriceable', {
          sku: this.sku,
          priceable: Object.assign({}, item),
        });
      } catch (error) {
        this.loadingUpdate = 0;
        console.log(error);
      }
    },
    async removePrice(item) {
      if (item.id !== -1) {
        await this.destroyPriceable(item.id);
      }
      const index = this.priceables.findIndex(priceable => priceable.uuid === item.uuid);
      this.priceables.splice(index, 1);
    },
    async destroyPriceable(priceableId) {
      try {
        this.loadingRemove = priceableId;
        await PriceableServices.destroy(this.user.archon.id, priceableId);
        this.$emit('onRemovePriceable', {
          sku: this.sku,
          priceableId,
        });
        this.loadingRemove = 0;
      } catch (error) {
        this.loadingRemove = 0;
        console.log(error);
      }
    },
    async storePriceable(priceable) {
      try {
        this.loadingStore = true;
        priceable.priceable_type = 'sku';
        priceable.priceable_id = this.sku.id;
        const response = await PriceableServices.store(this.user.archon.id, priceable);
        priceable.id = response.data.id;
        this.priceables.unshift(priceable);
        this.$emit('onSendPriceable', {
          sku: this.sku,
          priceable: response.data, 
        });
        this.loadingStore = false;
        this.$notify({
          group: 'petition',
          title: 'Precios',
          type: 'success',
          text: 'Precio agregado',
        });
      } catch (error) {
        this.loadingStore = false;
        console.log(error);
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    submitNone() {
      return false;
    },
    resetData() {
      this.priceable = {
        id: -1,
        price_id: '',
        value: '',
        package_id: '',
        units: '',
      };
      this.$refs['form'].resetValidation();
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: 'Tipo',
          sortable: false,
          value: 'price_name',
        },
        {
          text: 'Paquete',
          sortable: false,
          value: 'package_name',
        },
        {
          text: 'Unid. por Paquete',
          sortable: false,
          value: 'units',
        },
        {
          text: 'Precio',
          sortable: false,
          value: 'value',
        },
        {
          text: 'Precio Unit.',
          sortable: false,
          value: 'price_unit',
        },
        { text: '', value: 'actions', sortable: false },
      ];
    },
  },
};
</script>

<style scoped>
.text__text-center >>> input {
  text-align: center;
}
</style>