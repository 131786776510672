<template>
  <v-card flat>
    <v-tabs
      v-model="tab"
      icons-and-text
      fixed-tabs
    >
      <v-tab>
        Precios
        <v-icon>mdi-currency-usd</v-icon>
      </v-tab>
      <v-tab>
        General
        <v-icon>mdi-menu</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat class="pa-2">
          <Prices
            :priceables="priceables"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat class="pa-2">
          <SkuDetail
            :sku="sku"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Prices from './SkuPrices';
import SkuDetail from './SkuDetail';
export default {
  components: {
    Prices,
    SkuDetail,
  },
  props: {
    sku: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    priceables() {
      return this.sku?.priceables ?? [];
    },
  },
};
</script>