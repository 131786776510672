r<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="80%"
  >
    <v-card>
      <v-card-title>
        <v-icon left>mdi-barcode</v-icon>
        Códigos de barra para {{ sku.sku }}
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          onsubmit="return false"
          style="width: 50%"
          class="d-flex justify-space-between"
        >
          <v-text-field
            label="Barcode"
            v-model="value"
            :rules="[isRequired]"
            dense
            @keyup.enter="addBarcode"
          />
          <v-btn
            @click="addBarcode"
            small
            icon
            color="primary"
            :loading="loadingStore"
            :disabled="loadingStore"
          >
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
        </v-form>
        <v-row>
          <v-col
            v-for="(barcode, index) in barcodes"
            :key="`barcode-${barcode.uuid}`"
            cols="12"
            md="6"
          >
            <BarcodeCard
              :key="`barcodec-${barcode.uuid}`"
              :barcode="barcode"
              :index="index"
              @onRemove="onRemove"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="grey lighten-1"
          @click="closeDialog"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// components
import BarcodeCard from '@/components/commons/barcodes/BarcodeCard';
// services
import BarcodeServices from '@/store/services/general/BarcodeServices';
import PriceableServices from '@/store/services/general/PriceableServices';
// helpers
import { isInteger } from '@/constants/rules';
import { mapGetters } from 'vuex';
import { uuid } from '@/constants/helpers';
export default {
  components: {
    BarcodeCard,
  },
  props: {},
  data() {
    return {
      dialog: false,

      value: '',

      barcodes: [],
      sku: {
        id: -1,
        sku: '',
      },

      // rules
      isRequired: value => !!value || 'Campo requerido',
      isInteger: value => isInteger(value) || 'Este campo debe ser entero',

      loadingStore: false,
      loadingRemove: 0,
      loadingUpdate: 0,
    };
  },
  methods: {
    async addBarcode() {
      if (this.$refs.form.validate()) {
        if (this.sku.id !== -1) await this.storeBarcode();
        else {
          // TODO add barcode
          this.barcodes.unshift({
            id: -1,
            uuid: uuid(),
            value: this.value,
            barcodable_id: this.sku.id,
            barcodable_type: 'sku',
            created_at: null,
          });
        }
        this.value = '';
        this.$refs['form'].resetValidation();
      }
    },
    async storeBarcode() {
      try {
        this.loadingStore = true;
        const barcode = {
          value: this.value,
          barcodable_id: this.sku.id,
          barcodable_type: 'sku',
        };
        const response = await BarcodeServices.store(this.user.archon.id, barcode);
        const newBarcode = response.data;
        newBarcode.uuid = uuid();
        this.barcodes.unshift(newBarcode);

        this.$emit('onSendBarcode', {
          sku: this.sku,
          barcode: response.data, 
        });
        this.loadingStore = false;
        this.resetData();
        this.$notify({
          group: 'petition',
          type: 'success',
          text: `Código de barras agregado a ${this.sku.sku}`,
        });
      } catch (error) {
        this.loadingStore = false;
        console.log(error);
      }
    },
    getPriceUnit(item) {
      return (item.value / item.units).toFixed(2);
    },
    existPriceableBuy(priceType, priceId) {
      if (priceType === 'SELL') return false;
      else {
        const index = this.priceables.findIndex(priceable => priceable.price_id === priceId);
        return index !== -1;
      }
    },
    async updatePrice(item) {
      try {
        const body = Object.assign({}, item);
        body.priceable_type = 'sku';
        body.priceable_id = this.sku.id;
        this.loadingUpdate = item.id;
        await PriceableServices.update(this.user.archon.id, item.id, body);
        this.loadingUpdate = 0;
        this.$notify({
          group: 'petition',
          title: 'Precios',
          type: 'success',
          text: 'Precio actualizado',
        });
        this.$emit('onUpdatePriceable', {
          sku: this.sku,
          priceable: Object.assign({}, item),
        });
      } catch (error) {
        this.loadingUpdate = 0;
        console.log(error);
      }
    },
    closeDialog() {
      this.sku = {
        id: -1,
        sku: '',
      };
      this.dialog = false;
    },
    submitNone() {
      return false;
    },
    resetData() {
      this.value = '';
      this.$refs['form'].resetValidation();
    },

    async onRemove(data) {
      if (data.barcode.id === -1) {
        const index = this.barcodes.findIndex(barcode => barcode.uuid === data.barcode.uuid);
        this.barcodes.splice(index, 1);
      } else {
        await this.removeBarcode(data.barcode, data.index);
      }
    },
    async removeBarcode(barcode, index) {
      try {
        await BarcodeServices.destroy(this.user.archon.id, barcode.id);
        this.barcodes.splice(index, 1);
        this.$emit('onRemoveBarcode', {
          sku: this.sku,
          barcodeId: barcode.id,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
};
</script>

<style scoped>
.text__text-center >>> input {
  text-align: center;
}
</style>