<template>
  <div>
    <Loader :loading="loading" />
    <div class="d-flex justify-space-between">
      <div class="d-flex justify-space-between">
        <div style="width: 150px;">
          <v-select
            v-model="perPage"
            label="Total visible"
            :items="listPerPage"
            @change="resetCurrentPage"
            dense
          />
        </div>
        <div class="ml-2" style="width: 150px;">
          <v-text-field
            v-model="filter"
            label="Buscar"
            @keyup.enter="resetCurrentPage"
            append-icon="mdi-magnify"
            dense
          />
        </div>
      </div>
      <div>
        <v-btn
          @click="getSkus"
          color="warning"
          small
          text
        >
          <v-icon left>mdi-refresh</v-icon>
          Refrescar
        </v-btn>
        <v-btn
          @click="createProduct"
          color="primary"
          small
          text
        >
          <v-icon left>mdi-plus</v-icon>
          Producto
        </v-btn>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="skus"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      fixed-header
      :height="heightSkus"
    >
      <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              link
              @click="openDetails(item)"
            >
              <v-icon left>mdi-menu</v-icon>
              <small>Detalles</small>
            </v-list-item>
            <v-list-item
              link
              @click="editSku(item)"
            >
              <v-icon left>mdi-pencil-box</v-icon>
              <small>Editar</small>
            </v-list-item>
            <v-list-item
              link
              @click="editPrices(item)"
            >
              <v-icon left>mdi-currency-usd</v-icon>
              <small>Precios</small>
            </v-list-item>
            <v-list-item
              link
              @click="editBarCodes(item)"
            >
              <v-icon left>mdi-barcode</v-icon>
              <small>Códigos</small>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.sku="{ item }">
        <div>
          <div class="text--secondary">
            <v-icon
              v-if="item.priceables.filter(priceable => priceable.price.code === 'SELL').length === 0"
              left
              small
              color="warning"
            >
              mdi-alert-outline
            </v-icon>
            <b> {{ item.sku }} </b>
            <div>
              <small> {{ item.description }} </small>
            </div>
          </div>
          <small class="text--primary"> {{ item.product.name }} </small>
        </div>
      </template>
      <template v-slot:item.stock="{ item }">
        <div>Min: {{ item.min_stock }}</div>
        <div>Max: {{ item.max_stock }}</div>
      </template>
      <template v-slot:item.brand="{ item }">
        <div v-if="item.product.brand === null">-</div>
        <div v-else> {{ item.product.brand.name }} </div>
      </template>
      <template v-slot:item.categories="{ item }">
        <v-chip-group column>
          <v-chip
            v-for="category in item.product.categories"
            x-small
            :key="`category-${category.id}`"
          >
            {{ category.name }}
          </v-chip>
        </v-chip-group>
      </template>
      <template v-slot:item.suppliers="{ item }">
        <v-chip-group column>
          <v-chip
            v-for="supplier in item.product.suppliers"
            x-small
            :key="`supplier-${supplier.id}`"
          >
            {{ supplier.entity.name }}
          </v-chip>
        </v-chip-group>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :disabled="loading"
        :length="lastPage"
        :total-visible="8"
      />
    </div>
    <v-navigation-drawer
      v-model="showDetails"
      absolute
      temporary
      right
      width="500"
    >
      <v-card flat>
        <DetailContainer
          :sku="currentSku" 
        />
      </v-card>
    </v-navigation-drawer>
    <Priceables
      ref="priceables"
      :prices="prices"
      :packages="packages"
      @onSendPriceable="onGetPriceable"
      @onUpdatePriceable="onUpdatePriceable"
      @onRemovePriceable="onRemovePriceable"
    />
    <Barcodes
      ref="barcodes"
      @onSendBarcode="onGetBarcode"
      @onRemoveBarcode="onRemoveBarcode"
    />
    <SkuEdit
      ref="skuEdit"
      @onUpdatedSku="onUpdatedSku"
    />
  </div>
</template>

<script>
// services
import PriceServices from '@/store/services/general/PriceServices';
import PackageServices from '@/store/services/warehouse/PackageServices';
import SkuServices from '@/store/services/inventory/SkuServices';
// components
import Priceables from '@/views/general/priceable/PriceablesCU';
import Barcodes from '@/views/general/barcode/BarcodesCU';
import DetailContainer from './details/SkuDetailContainer';
import SkuEdit from '@/components/commons/sku/SkuEdit';
// helpers
import { mapGetters } from 'vuex';
import { uuid } from '@/constants/helpers';
import ListMixin from '@/mixins/commons/ListMixin';
export default {
  mixins: [ListMixin],
  components: {
    DetailContainer,
    Priceables,
    Barcodes,
    SkuEdit,
  },
  props: {
    supplier: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      skus: [],

      currentSku: {
        id: -1,
      },
      showDetails: false,

      heightSkus: window.innerHeight - 200,
      filter: '',

      prices: [],
      packages: [],
    };
  },
  created() {
    this.getSkus();
    this.getPrices();
    this.getPackages();
  },
  methods: {
    onGetPriceable(data) {
      const index = this.skus.findIndex(sku => sku.id === data.sku.id);
      this.skus[index].priceables.push(data.priceable);
    },
    onUpdatePriceable(data) {
      const indexSku = this.skus.findIndex(sku => sku.id === data.sku.id);
      const indexPriceable = this.skus[indexSku].priceables.findIndex(priceable => priceable.id === data.priceable.id);
      this.skus[indexSku].priceables[indexPriceable].value = data.priceable.value;
      this.skus[indexSku].priceables[indexPriceable].units = data.priceable.units;
    },
    onRemovePriceable(data) {
      const indexSku = this.skus.findIndex(sku => sku.id === data.sku.id);
      const indexPriceable = this.skus[indexSku].priceables.findIndex(priceable => priceable.id === data.priceableId);
      this.skus[indexSku].priceables.splice(indexPriceable, 1);
    },
    onRemoveBarcode(data) {
      const indexSku = this.skus.findIndex(sku => sku.id === data.sku.id);
      const indexBarcode = this.skus[indexSku].barcodes.findIndex(barcode => barcode.id === data.barcodeId);
      this.skus[indexSku].barcodes.splice(indexBarcode, 1);
    },
    onUpdatedSku(data) {
      const indexSku = this.skus.findIndex(sku => sku.id === data.sku.id);
      this.skus[indexSku].sku = data.sku.sku;
      this.skus[indexSku].description = data.sku.description;
    },
    onGetBarcode(data) {
      const indexSku = this.skus.findIndex(sku => sku.id === data.sku.id);
      this.skus[indexSku].barcodes.unshift(data.barcode);
    },
    openDetails(item) {
      this.showDetails = true;
      this.currentSku = item;
    },
    editSku(data) {
      this.$refs['skuEdit'].loadData(data);
    },
    editPrices(item) {
      this.$refs['priceables'].dialog = true;
      const sku = Object.assign({}, item);
      delete sku.priceables;
      this.$refs['priceables'].sku = Object.assign({}, sku);
      this.$refs['priceables'].priceables = item.priceables.map(priceable => ({
        uuid: uuid(),
        id: priceable.id,
        package_id: priceable.package.id,
        package_name: priceable.package.name,
        price_id: priceable.price.id,
        price_name: priceable.price.name,
        price_type: priceable.price.type,
        units: priceable.units,
        value: priceable.value,
      }));
    },
    editBarCodes(item) {
      this.$refs['barcodes'].dialog = true;
      const sku = Object.assign({}, item);
      delete sku.barcodes;
      this.$refs['barcodes'].sku = Object.assign({}, sku);
      this.$refs['barcodes'].barcodes = item.barcodes.map(barcode => ({
        ...barcode,
        uuid: uuid(),
      }));
    },
    async getSkus() {
      try {
        const params = {
          page: this.currentPage,
          order_by: 'id',
          order_direction: 'DESC',
          per_page: typeof this.perPage === 'string' ? this.total : this.perPage,
          include: 'product.brand,product.categories,priceables.price,priceables.package,product.suppliers.entity,barcodes',
          filter: this.filter,
        };
        this.loading = true;
        const response = await SkuServices.index(this.user.archon.id, params);
        this.skus = response.data.data;
        this.currentPage = response.data.current_page;
        this.lastPage = response.data.last_page;
        this.total = response.data.total;
        
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async getPrices() {
      try {
        const response = await PriceServices.index(this.user.archon.id);
        this.prices = response.data.data;
      } catch (error) {
        this.$notify({
          group: 'petition',
          title: 'Precios',
          type: 'error',
          text: error,
        });
      }
    },
    async getPackages() {
      try {
        const response = await PackageServices.index(this.user.archon.id);
        this.packages = response.data.data;
      } catch (error) {
        this.$notify({
          group: 'petition',
          title: 'Paquetes',
          type: 'error',
          text: error,
        });
      }
    },
    createProduct() {
      this.$router.push({
        name: 'productCreate',
      });
    },
    // list
    resetCurrentPage() {
      if (this.currentPage === 1) this.getSkus();
      else this.currentPage = 1;
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
        {
          text: 'SKU',
          sortable: false,
          value: 'sku',
        },
        // { text: 'Stock', value: 'stock', sortable: false },
        { text: 'Marca', value: 'brand', sortable: false },
        { text: 'Categorias', value: 'categories', sortable: false },
        { text: 'Proveedor', value: 'suppliers', sortable: false },
      ];
    },
  },
  watch: {
    currentPage() {
      this.getSkus();
    },
    currentSupplier() {},
  },
}
</script>