<template>
  <v-dialog v-model="dialog" width="50%">
    <v-card>
      <LoaderDotSpinner absolute :loading="loading"/>
      <v-card-title class="amber lighten-2">
        <v-icon left>mdi-pencil-box</v-icon>
        Editar {{ sku.sku }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" class="mt-4">
          <v-text-field
            v-model="name"
            label="Sku"
            :rules="[isRequired]"
          />
          <v-text-field
            v-model="description"
            label="Descripción"
            :rules="[isRequired]"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="grey lighten-1"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          small
          color="warning"
          @click="updateSku"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SkuServices from '@/store/services/inventory/SkuServices';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      sku: {
        sku: '',
      },
      name: '',
      description: '',
      dialog: false,
      loading: false,

      // rules
      isRequired: value => !!value || 'Campo requerido',
    };
  },
  methods: {
    async updateSku() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          await SkuServices.update(this.user.archon.id, this.sku.id, {
            product_id: this.sku.product_id,
            sku: this.name,
            description: this.description,
            min_stock: 0,
            max_stock: 0,
          });
          this.loading = false;
          this.$emit('onUpdatedSku', {
            sku: {
              id: this.sku.id,
              sku: this.name,
              description: this.description,
              min_stock: 0,
              max_stock: 0,
            },
          });
          this.resetData();
          this.dialog = false;
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      }
    },
    loadData(sku) {
      this.sku = Object.assign({}, sku);
      this.name = sku.sku;
      this.description = sku.description;

      this.dialog = true;
    },
    closeDialog() {
      this.resetData();
      this.dialog = false;
    },
    resetData() {
      this.name = '';
      this.description = '';
      this.sku = {
        sku: '',
      };
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
}
</script>

<style scoped>

</style>